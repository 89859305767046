import React, { useCallback, useState, useEffect, useMemo, useRef } from "react";
import '../App.css';
import '@fortawesome/fontawesome-free/css/all.css';


export default function ProjectItems({
  index,
  name,
  features,
  tools,
  description,
  link,
  gitLink,
  images
}) {
  const [currentImage, setCurrentImage] = useState(0);
  const [opacity, setOpacity] = useState(1);

  // function to alternate between images
  useEffect(() => {
    const timer = setInterval(() => {
      setOpacity(0);
      setTimeout(() => {
        setCurrentImage((currentImage + 1) % images.length);
        setOpacity(1);
      }, 1000);
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [currentImage]);

  return (
    <div className="grid lg:grid-cols-2 w-full lg:min-h-[400px] relative">
      {
        index === 1 || index === 3 ?
        <div className="flex flex-col justify-center text-start z-20 gap-2 sm:gap-0">
          <div className="font-press-start-2p text-xs lg:text-sm 3xl:text-lg text-back-300">
            web3 application
          </div>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <div className="text-back-400 text-lg lg:text-2xl 3xl:text-4xl font-press-start-2p mb-1 lg:mb-4 hover:text-other-100">
              {name}
            </div>
          </a>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <img src={images[0]} className="w-full h-full mb-2 z-0 object-cover shadow-2xl hover:border-other-100 shadow-2xl lg:hidden block"/>
          </a>
          <div className="bg-[#F5F5F5] shadow-xl text-[10px] font-league-spartan lg:text-sm 3xl:text-lg text-back-400 font-lato-bold mb-4 p-2 lg:p-4">
            {description}
          </div>
          <div className="hidden sm:flex grid grid-cols-5 gap-2 items-center justify-center mb-1 lg:mb-4 font-press-start-2p">
              {
                tools.map((tool) => {
                  return (
                    <div className="bg-back-400 w-full h-full flex items-center justify-center text-center text-back-100 sm:text-[8px] py-1 3xl:text-md">
                    {tool}
                    </div>
                  )
                })
              }
          </div>
          <div className="hidden sm:flex justify-start items-center text-center gap-3 text-lg lg:text-2xl 3xl:text-4xl text-back-400">
            <a href={gitLink} target="_blank" rel="noopener noreferrer" className="hover:text-other-100">
              <i class="fab fa-github"></i>
            </a>
            <a href={link} target="_blank" rel="noopener noreferrer" className="hover:text-other-100">
              <i class="fa-solid fa-link"></i>
            </a>
          </div>
        </div>
        :
        ""
      }
      <div>
        <a href={link} target="_blank" rel="noopener noreferrer" className={`absolute ${index === 0 || index === 2 ? 'left-0' : 'right-0'} top-1/2 transform -translate-y-1/2 lg:w-[65%] lg:h-auto z-10 transition-opacity duration-300 shadow-2xl hover:opacity-0 hidden lg:block`}>
          <img src={images[0]} className="object-cover h-full w-full"/>
        </a>
        <a href={link} target="_blank" rel="noopener noreferrer" className={`absolute ${index === 0 || index === 2 ? 'left-0' : 'right-0'} top-1/2 transform -translate-y-1/2 lg:w-[65%] lg:h-auto z-0 shadow-2xl hidden lg:block`}>
          <img src={images[1]} className="object-cover h-full w-full"/>
        </a>
      </div>
      {
        index === 0 || index === 2 ?
        <div className="flex flex-col justify-center text-end z-20 gap-2 sm:gap-0">
          <div className="font-press-start-2p text-xs lg:text-sm 3xl:text-lg text-back-300">
            web3 application
          </div>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <div className="text-back-400 text-lg lg:text-2xl 3xl:text-4xl font-press-start-2p mb-1 lg:mb-4 hover:text-other-100">
              {name}
            </div>
          </a>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <img src={images[0]} className="w-full h-full mb-2 z-0 object-cover shadow-2xl hover:border-other-100 shadow-2xl lg:hidden block"/>
          </a>
          <div className="bg-[#F5F5F5] shadow-xl text-[10px] font-league-spartan lg:text-sm 3xl:text-lg text-back-400 font-lato-bold mb-4 p-2 lg:p-4">
            {description}
          </div>
          <div className="hidden sm:flex grid grid-cols-5 gap-2 items-center justify-center mb-1 lg:mb-4 font-press-start-2p">
              {
                tools.map((tool) => {
                  return (
                    <div className="bg-back-400 w-full h-full flex items-center justify-center text-center text-back-100 text-[8px] py-1 3xl:text-md">
                    {tool}
                    </div>
                  )
                })
              }
          </div>
          <div className="hidden sm:flex justify-end items-center text-center gap-3 text-lg lg:text-2xl 3xl:text-4xl text-back-400">
            <a href={gitLink} target="_blank" rel="noopener noreferrer" className="hover:text-other-100">
              <i class="fab fa-github"></i>
            </a>
            <a href={link} target="_blank" rel="noopener noreferrer" className="hover:text-other-100">
              <i class="fa-solid fa-link"></i>
            </a>
          </div>
        </div>
        :
        ""
      }
    </div>
  )


}
